import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import SmallBanner from "@components/common/SmallBanner";
import "@styles/Privacidad.css";

export default function Index() {
    return (
        <>
            <Layout>
                <Seo title="Política de Cookies" />
                <SmallBanner title="Política de Cookies" filename="bgs/bgPrivacy" />
                <div className="container lg:px-0 py-20">
                    <Text className="text-justify italic">
                        Debido a la entrada en vigor de la modificación de la "Ley de Servicios de
                        la Sociedad de la Información" (LSSICE) establecida por el Real Decreto
                        13/2012, es de obligación obtener el consentimiento expreso del usuario de
                        todas las páginas web que utilizan cookies antes de que éste navegue por
                        ellas.
                    </Text>

                    <div className="mb-10 privacidad mt-10">
                        <Title className="text-left">¿Qué son las Cookies?</Title>
                        <Text className="text-justify">
                            Las cookies y otras tecnologías similares tales como local shared
                            objects, flash cookies o píxeles, son herramientas utilizadas por los
                            servidores web para almacenar y recuperar información acerca de sus
                            visitantes, así como para ofrecer un correcto funcionamiento del sitio.
                            <br />
                            <br />
                            Mediante el uso de estos dispositivos se permite al servidor web
                            recordar algunos datos concernientes al usuario, como sus preferencias
                            para la visualización de las páginas de este servidor, productos que más
                            le interesan, etc.
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">
                            Cookies afectadas por la normativa y cookies excluidas
                        </Title>
                        <Text className="text-justify">
                            Según la directiva de la UE, las cookies que requieren el consentimiento
                            informado por parte del usuario son las cookies analíticas y las de
                            publicidad y afiliación, quedando exceptuadas las de carácter técnico y
                            aquellas necesarias para la prestación de un servicio expresamente
                            solicitado por el usuario.
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">¿Qué tipos de cookies existen?</Title>
                        <Text className="text-justify">
                            Las cookies se pueden agrupar en cinco grupos:
                            <ul className="ml-5 list-disc mt-5">
                                <li className="mb-2">
                                    <strong>Cookies analíticas:</strong> recogen información del uso
                                    que se realiza del sitio web.
                                </li>
                                <li className="mb-2">
                                    <strong>Cookies sociales:</strong> son aquellas necesarias para
                                    redes sociales externas.
                                </li>
                                <li className="mb-2">
                                    <strong>Cookies de afiliados:</strong> permiten hacer un
                                    seguimiento de las visitas procedentes de otras webs, con las
                                    que el sitio web establece un contrato de afiliación (empresas
                                    de afiliación).
                                </li>
                                <li className="mb-2">
                                    <strong>Cookies de publicidad:</strong> recogen información
                                    sobre las preferencias y elecciones personales del usuario
                                    (retargeting).
                                </li>
                                <li>
                                    <strong>Cookies técnicas y funcionales:</strong> son las
                                    estrictamente necesarios para el uso del sitio web y para la
                                    prestación del servicio contratado.
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">Cookies que utiliza nuestro sitio web</Title>
                        <Text className="text-justify">
                            Nuestro sitio web utiliza cookies de los diferentes tipos mencionados en
                            el apartado anterior.
                        </Text>
                    </div>

                    <div className="privacidad">
                        <Title className="text-left">Administración de cookies</Title>
                        <Text className="text-justify">
                            Puedes permitir, bloquear o eliminar las cookies instaladas en tu
                            ordenador mediante la configuración de las opciones del navegador
                            instalado en tu ordenador.
                            <ul className="ml-5 list-disc mt-5">
                                <li>
                                    Para más información sobre el navegador Firefox visita el
                                    siguiente{" "}
                                    <a
                                        href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        enlace
                                    </a>
                                    .{" "}
                                </li>
                                <li>
                                    Para más información sobre el navegador Chrome visita el
                                    siguiente{" "}
                                    <a
                                        href="https://support.google.com/chrome/answer/95647?hl=es"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        enlace
                                    </a>
                                    .{" "}
                                </li>
                                <li>
                                    Para más información sobre el navegador Explorer visita el
                                    siguiente{" "}
                                    <a
                                        href="https://support.google.com/chrome/answer/95647?hl=es"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        enlace
                                    </a>
                                    .{" "}
                                </li>
                                <li>
                                    Para más información sobre el navegador Safari visita el
                                    siguiente{" "}
                                    <a
                                        href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        enlace
                                    </a>
                                    .{" "}
                                </li>
                                <li>
                                    Para más información sobre el navegador Opera visita el
                                    siguiente{" "}
                                    <a
                                        href="http://help.opera.com/Windows/11.50/es-ES/cookies.html"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        enlace
                                    </a>
                                    .{" "}
                                </li>
                                <li>
                                    Para más información sobre el navegador Edge visita el siguiente{" "}
                                    <a
                                        href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        enlace
                                    </a>
                                    .
                                </li>
                            </ul>
                        </Text>
                    </div>
                </div>
            </Layout>
        </>
    );
}
